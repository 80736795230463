@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "W95";
    src: local('W95'), url(../src/assets/fonts/w95fa.woff) format('woff');
}

body {
    overflow: hidden; /* Hide scrollbars */
  }